import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { media } from '../../utils/media-queries';

const Nav = () => (
  <StyledNav>
    <ul>
      <li className="sp-btm">
        <Link to="/projekte">Projekte</Link>
      </li>
      <li className="sp-btm">
        <Link to="/vita">Vita</Link>
      </li>
      <li>
        <Link to="/kontakt">Kontakt</Link>
      </li>
    </ul>
  </StyledNav>
);

const StyledNav = styled.nav`
  width: 100%;
  display: none;

  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a {
      display: inline;

      &:hover {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 1px;
          height: 1px;
          width: 100%;
          background: var(--b);
        }
      }
    }
  }

  @media ${media.M} {
    display: block;
  }
`;

export default Nav;
