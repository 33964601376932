import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { AnimatePresence } from 'framer-motion';
import Nav from '../atoms/nav';
import RichText from '../atoms/richtext';
import Video from '../../assets/videos/ejr.mp4';
import { media } from '../../utils/media-queries';
import Intro from '../sections/frontpage/intro';
import NavMobile from '../atoms/navMobile';

const Header = ({ desc }) => {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <>
      <StyledHeader>
        <h1>
          <Link to="/">
            <p className="sp-btm">Erik</p>
            <p className="sp-btm">Jan</p>
            <p>Rippmann</p>
          </Link>
        </h1>
        <Nav />
      </StyledHeader>
      <StyledMobileNav>
        <button
          className="menu-btn"
          type="button"
          onClick={() => setNavOpen(!navOpen)}
        >
          MENÜ
        </button>
        <AnimatePresence>
          {navOpen && (
            <NavMobile showNav={navOpen} onClick={() => setNavOpen(false)} />
          )}
        </AnimatePresence>
      </StyledMobileNav>
      <StyledFixedHeader>
        <div className="fix">
          <p className="name underl">Erik Jan Rippmann</p>
          <div className="desc">
            <RichText blocks={desc} />
          </div>
        </div>
        <Nav />
        <video src={Video} autoPlay playsInline muted loop />
      </StyledFixedHeader>
      <Intro />
    </>
  );
};

const StyledHeader = styled.header`
  display: flex;
  position: absolute;
  top: 0;
  color: var(--w);
  padding: var(--sp-2-3);
  z-index: 2;

  h1 {
    padding-right: var(--sp-3);
  }

  ul {
    a {
      &:hover {
        &:after {
          background: var(--w);
        }
      }
    }
  }

  .sp-btm {
    padding-bottom: var(--sp-1);
  }
`;

const StyledMobileNav = styled.div`
  .menu-btn {
    padding: var(--sp-2-3);
    position: fixed;
    z-index: 3;
    right: 0;
    color: var(--w);
    mix-blend-mode: difference;
  }

  @media ${media.M} {
    display: none;
  }
`;

const StyledFixedHeader = styled.div`
  position: fixed;
  top: 0;
  padding: var(--sp-1-3);
  max-width: 27%;
  z-index: 1;

  .name {
    font-weight: bold;
    display: inline-block;
  }

  .desc {
    padding: var(--sp-1-3) 0;
    text-transform: none;
  }

  video {
    width: 15rem;
    padding-top: var(--sp-5);
    transform: translateX(-3.2rem);
  }

  .name,
  .desc,
  video {
    display: none;
  }

  @media ${media.M} {
    .name,
    .desc,
    video {
      display: block;
    }

    .name {
      display: inline-block;
    }
  }
`;

Header.propTypes = {
  desc: PropTypes.array,
};

export default Header;
