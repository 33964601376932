import React, { useState } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { media } from '../../utils/media-queries';
import { ease } from '../../utils/easing';

const ProjectPreview = ({
  title,
  desc,
  country,
  url,
  link,
  date,
  src,
  alt,
  bg,
  slug,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <Link
      to={slug}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <StyledProjectPreview className="grid">
        <div className="text">
          <h3 className="underl">{title}</h3>
          <p className="desc">{desc}</p>
          <p>{country}</p>
          <a
            className="link underl"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {link}
          </a>
        </div>
        <div className="img">
          <div className="date underl">{date}</div>
          <motion.div style={{ background: `${bg}` }}>
            <motion.figure
              initial={{ opacity: 1 }}
              animate={{ opacity: hover ? 0.05 : 1 }}
              transition={{ duration: 0.15, ease: ease.inSnap }}
            >
              <GatsbyImage image={src} alt={alt} />
            </motion.figure>
          </motion.div>
        </div>
      </StyledProjectPreview>
    </Link>
  );
};

const StyledProjectPreview = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: var(--sp-3);

  .text {
    order: 2;
    padding-left: var(--sp-2-3);

    h3 {
      display: inline-block;
      font-weight: bold;
    }

    .desc {
      text-transform: none;
      padding: var(--sp-2) 0;
    }

    .link {
      display: inline-block;
    }
  }

  .img {
    order: 1;
    pointer-events: none;

    .date {
      display: inline-block;
      font-weight: bold;
      margin-left: var(--sp-2-3);
      margin-bottom: var(--sp-2);
    }

    .gatsby-image-wrapper {
      display: block;
    }
  }

  @media ${media.M} {
    display: grid;
    padding-left: var(--sp-2-3);

    .text {
      padding-left: 0;
      order: 0;
      grid-column: 8 / 14;
    }

    .img {
      order: 0;
      grid-column: 15 / 25;

      .date {
        margin-left: 0;
      }
    }
  }
`;

ProjectPreview.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  country: PropTypes.string,
  url: PropTypes.string,
  link: PropTypes.string,
  date: PropTypes.string,
  src: PropTypes.object,
  alt: PropTypes.string,
};

export default ProjectPreview;
