import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  AnimatePresence,
  motion,
  useTransform,
  useViewportScroll,
} from 'framer-motion';
import Poster from '../../../assets/images/video-preview.png';
import { ease } from '../../../utils/easing';
import { LayoutContext } from '../../organisms/layoutProvider';
import { useWindowSize } from '../../../hooks/useWindowSize';

const Intro = () => {
  const size = useWindowSize();
  const { scrollYProgress } = useViewportScroll();
  const y = useTransform(scrollYProgress, [0, 0.33], [0, 700]);
  const { initialLoad, setInitialLoad } = useContext(LayoutContext);
  const [height, setHeight] = useState();

  useEffect(() => {
    setHeight(size.height);
    setTimeout(() => setInitialLoad(true), 600);
  }, [setInitialLoad, size]);

  return (
    <StyledIntro>
      <AnimatePresence>
        <div style={{ height }} className="hl-wrapper">
          <motion.h2
            initial={{
              scale: initialLoad ? 1 : 2,
              opacity: initialLoad ? 1 : 0,
            }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6, ease: ease.outSnap, delay: 0.2 }}
          >
            Erik Jan <br />
            Rippmann
          </motion.h2>
        </div>
      </AnimatePresence>
      <figure>
        <motion.video
          style={{ y }}
          src="https://player.vimeo.com/external/543570582.hd.mp4?s=4537cfcb2812b0a08553f5a1f8fd73477bcdbc65&profile_id=174"
          poster={Poster}
          playsInline
          loop
          muted
          autoPlay
        />
      </figure>
    </StyledIntro>
  );
};

const StyledIntro = styled(motion.section)`
  position: absolute;
  z-index: 1;
  width: 100%;

  .hl-wrapper {
    z-index: 1;
    transition: 0.3s;
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    overflow: hidden;

    h2 {
      font-size: 20vw;
      text-align: center;
      color: var(--w);
      letter-spacing: -0.8vw;
      line-height: 0.7;
    }
  }

  figure {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: var(--b);

    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
`;

export default Intro;
