import React, { useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
  enableBodyScroll,
} from 'body-scroll-lock';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { media } from '../../utils/media-queries';
import Close from './close';
import Footer from '../molecules/footer';
import { ease } from '../../utils/easing';

const NavMobile = ({ showNav = false, onClick }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (showNav) {
      disableBodyScroll(ref.current);
    } else if (!showNav) {
      enableBodyScroll(ref.current);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [showNav]);

  return (
    <StyledNavMobile
      ref={ref}
      key="mobilenav"
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0.9 }}
      transition={{ duration: 0.6, ease: ease.inOutSnap }}
    >
      <Close onClick={onClick} />
      <ul onClick={onClick}>
        <li>
          <Link className="hl outl" to="/projekte">
            Projekte
          </Link>
        </li>
        <li>
          <Link className="hl outl" to="/vita">
            Vita
          </Link>
        </li>
        <li>
          <Link className="hl outl" to="/kontakt">
            Kontakt
          </Link>
        </li>
      </ul>
      <Footer />
    </StyledNavMobile>
  );
};

const StyledNavMobile = styled(motion.nav)`
  position: fixed;
  background: var(--w);
  width: 100vw;
  height: 100vh;
  padding: var(--sp-2-3);
  z-index: 9;
  color: var(--b);

  .close {
    color: var(--b);
    transform: translateY(-10px) translateX(1rem);
  }

  .footer {
    color: var(--b);
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: var(--sp-8);
    padding-top: var(--sp-5);

    li {
      a {
        transition: color 0.3s;

        &:hover {
          color: var(--b);
        }
      }
    }
  }

  @media ${media.M} {
    display: none;
  }
`;

NavMobile.propTypes = {
  showNav: PropTypes.bool,
  onClick: PropTypes.func,
};

export default NavMobile;
