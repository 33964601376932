import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { media } from '../../../utils/media-queries';

const About = ({ items }) => (
  <StyledAbout className="grid hl">
    <h3>
      Kultur + <br /> Wirtschaft
    </h3>
    <div className="items">
      {items.map((item, i) => (
        <p key={i} className="outl">
          {item}
        </p>
      ))}
    </div>
  </StyledAbout>
);

const StyledAbout = styled.section`
  padding: var(--sp-5) 0 var(--sp-5) var(--sp-2-3);
  overflow-x: hidden;

  h3 {
    grid-column: 8 / 23;
  }

  .items {
    padding-top: var(--sp-5);
    grid-column: 1 / 23;
  }

  p {
    padding-bottom: var(--sp-0);
  }

  @media ${media.M} {
    padding: var(--sp-13) 0 var(--sp-13) var(--sp-2-3);

    h3 {
      grid-column: 14 / 23;
    }

    .items {
      grid-column: 8 / 23;
    }
  }
`;

About.propTypes = {
  items: PropTypes.array,
};

export default About;
