import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from '../components/organisms/seo';
import About from '../components/sections/frontpage/about';
import ProjectPreview from '../components/molecules/projectPreview';
import Header from '../components/molecules/header';

const IndexPage = ({ data }) => {
  const { intro, projects, _rawDescription } = data.homepage.nodes[0];

  return (
    <>
      <SEO />
      <Header desc={_rawDescription} />
      <div style={{ height: '100vh' }} />
      <About items={intro} />
      <section>
        {projects.map((project, i) => (
          <ProjectPreview
            key={i}
            slug={`/projekte/${project.slug.current}`}
            title={project.title}
            desc={project.previewDescription}
            country={project.country}
            date={project.date}
            src={
              project.images.find(
                (item) => item.__typename === 'SanityMainImage'
              )?.image.asset.gatsbyImageData
            }
            alt={
              project.images.find(
                (item) => item.__typename === 'SanityMainImage'
              )?.alt
            }
            url={project.link?.url}
            link={project.link?.title}
            bg={project.bgColor?.hex}
          />
        ))}
      </section>
    </>
  );
};

export const query = graphql`
  query Homepage {
    homepage: allSanityHomepage {
      nodes {
        intro
        _rawDescription
        projects {
          country
          date
          previewDescription
          slug {
            current
          }
          title
          photoCredits
          link {
            title
            url
          }
          images {
            ... on SanityMainImage {
              __typename
              image {
                asset {
                  gatsbyImageData
                }
              }
              alt
            }
          }

          bgColor {
            hex
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object,
};

export default IndexPage;
